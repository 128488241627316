import { canUseDOM } from 'exenv';

export function popupCenter(event, url, w, h) {
  if (canUseDOM) {
    event.preventDefault();

    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screen.top;
    const width = window.innerWidth;
    const height = window.innerHeight;

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/open
    const newWindow = window.open(
      url,
      'FC Share',
      `scrollbars=no, width=${w}, height=${h}, top=${top}, left=${left}`
    );

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus();
    }
  }
}

/**
 * Sends a social event to GA to track user share activity
 * @param {String} socialPlatform (Facebook, Twitter, etc)
 * @param {String} action (Share, Tweet, etc)
 */
export function trackSocial(socialPlatform, action) {
  if (canUseDOM && window.ga) {
    window.ga('send', 'social', socialPlatform, action, window.location.href);
    window.ga(
      'rollup.send',
      'social',
      socialPlatform,
      action,
      window.location.href
    );
  }
}
