import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import Social from '../social/social';
import FCLink from '../fc_link/fc_link';
import get from 'lodash/get';

class Top50 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      background: ''
    };
  }

  onHoverCompany(url = '') {
    this.setState({
      background: url
    });
  }

  pad2(number) {
    return (number < 10 ? '0' : '') + number;
  }

  render() {
    const { companies, config } = this.props;
    const { background } = this.state;

    return (
      <div className="top-50" ref={this.props.forwardRef}>
        <div
          className="top-50__background"
          style={{ backgroundImage: `url(${background})` }}
        />
        <div className="top-50__container">
          <div
            className="top-50__scroll-container"
            id="top-50-scroll-container"
          >
            <Sticky
              top={100}
              bottomBoundary="#top-50-scroll-container"
              enabled={this.props.isDesktop}
            >
              <button
                className="mic-2020__button"
                onClick={() =>
                  this.props.scrollToSection(this.props.forwardRef)
                }
              >
                <span>TOP 50</span>
              </button>
              <button
                className="mic-2020__button"
                onClick={() =>
                  this.props.scrollToSection(this.props.sectorsRef)
                }
              >
                <span>Sectors</span>
              </button>
              <Social
                empty={false}
                enableSticky={false}
                modifier="mic-2020"
                uri="https://www.fastcompany.com/most-innovative-companies/2020"
                text={{
                  twitter: encodeURIComponent(
                    `These are the 50 Most Innovative Companies of 2020`
                  ),
                  linkedin: encodeURIComponent(
                    `These are the 50 Most Innovative Companies of 2020`
                  ),
                  email: encodeURIComponent(
                    `These are the 50 Most Innovative Companies of 2020 https://fastcompany.com/most-innovative-companies/2020`
                  )
                }}
                subject="These are the 50 Most Innovative Companies of 2020"
              />
            </Sticky>
          </div>
          <div className="top-50__content">
            <header className="top-50__header">
              <h3>Top 50</h3>
            </header>
            <div className="top-50__list-container">
              <ul className="top-50__list">
                {companies.map((company, index) => (
                  <li className="top-50__item">
                    <FCLink
                      title={company.title}
                      to={company.link}
                      config={config}
                    >
                      <span className="top-50__rank">
                        {this.pad2(index + 1)}
                      </span>{' '}
                      <span
                        className="top-50__company"
                        onMouseEnter={() =>
                          this.onHoverCompany(
                            `https://images.fastcompany.net/image/upload/h_2000,ar_1:1,c_fill,g_auto,f_auto,q_auto,fl_lossy/v1582234198/MIC/2020/MIC.${this.pad2(
                              index + 1
                            )}.png`
                          )
                        }
                        onMouseLeave={() => this.onHoverCompany('')}
                      >
                        {get(
                          company,
                          'customFields.most_innovative_companies.company',
                          ''
                        )}
                      </span>
                      <span className="top-50__description">
                        {get(
                          company,
                          'customFields.most_innovative_companies.reason',
                          ''
                        )}
                      </span>
                    </FCLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Top50.defaultProps = {
  isDesktop: true
};

Top50.propTypes = {
  isDesktop: PropTypes.bool,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  sectorsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  scrollToSection: PropTypes.func.isRequired,
  companies: PropTypes.shape([]).isRequired,
  config: PropTypes.shape({}).isRequired
};

export default Top50;
