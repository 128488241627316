import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Link from 'react-router-dom/Link';
import parse from 'url-parse';
// import brandWorksIcon from '../../../assets/icons/brandworks_icon.png';

export default class FCLink extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      config,
      title,
      children,
      externalLink,
      targetWindow,
      className
    } = this.props;
    // console.log('children', children);
    let { to } = this.props;
    // console.log('✅ to', to);
    const { site, env, amp } = config;
    const development = env === 'development';
    let sameDomain = !amp && to.indexOf(site) !== -1;
    const external = externalLink || to.indexOf('/videos/list/') !== -1;
    let relativePath;
    let href;
    // 💀
    // let queryParams = '';
    // if (to.includes('?')) {
    //   queryParams = to.slice(to.indexOf('?'));
    //   to = to.slice(0, to.indexOf('?'));
    // }

    if (to.includes('adurl=')) {
      sameDomain = false;
    }

    if (to.includes('news.')) {
      to = `//${to}`;
      return (
        <a className={className} href={to} title={title}>
          {children}
        </a>
      );
    }

    if (to.includes('magazine.')) {
      // to = `://${to}`;
      return (
        <a className={className} href={to} title={title}>
          {children}
        </a>
      );
    }

    // Use local TLD with port if dev.
    if (development && !external) {
      href = to
        .replace(/http(s)?:\/\/(www.)?/, 'http://')
        .replace('.com', '.local:8880');
    }

    // If we are on the current site use the link component to avoid page refresh.
    if (sameDomain && !external) {
      relativePath = parse(to, true).pathname;
      // Redirect MCP tag to MCP category.
      if (relativePath === '/section/most-creative-people') {
        relativePath = '/most-creative-people';
      }
    }

    if (relativePath) {
      // 💀
      // relativePath += queryParams;
      return (
        <a className={className} href={relativePath} title={title}>
          {children}
        </a>
      );
    }
    // 💀
    // if (href) {
    //   href += queryParams;
    // }
    // to += queryParams;

    if (targetWindow === 'blank') {
      return (
        <a
          className={className}
          href={href || to}
          title={title}
          target="_blank"
        >
          {children}
        </a>
      );
    }

    return (
      <a className={className} href={href || to} title={title}>
        {children}
      </a>
    );
  }
}

FCLink.defaultProps = {
  to: '',
  externalLink: false,
  title: '',
  targetWindow: '',
  className: ''
};

FCLink.propTypes = {
  config: PropTypes.shape({}).isRequired,
  to: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  externalLink: PropTypes.bool,
  targetWindow: PropTypes.string,
  className: PropTypes.string
};
