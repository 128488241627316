/* eslint-disable array-callback-return, consistent-return */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import socialModel from './social_models';
import { popupCenter, trackSocial } from '../../../utils/share_util';
import { trainParselyProfile } from '../../../client/utils/third_party_trackers/parsely';
import EventModel from '../../../utils/tracking/models';
import { get } from 'lodash';
import clsx from 'clsx';
import SocialIcon from '../../components/footer/icons';

function handleClick(event, url, popup, socialShare, props, index, isRedesign) {
  if (socialShare.action !== 'email') {
    event.preventDefault();
  }

  if (socialShare.action === 'link') {
    try {
      navigator.clipboard.writeText(url);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        `Clipboard API not supported. Make sure you're using HTTPS or are on localhost. \n`,
        err.stack
      );
    }
  }

  const socialClickEvent = new EventModel(
    'Post Social Click',
    `social${socialShare.platform}`,
    'postSocial',
    `social ${socialShare.platform}`,
    index + 1
  );

  socialClickEvent.triggerTrackingEvent(
    'default-click',
    socialClickEvent.getTrackingObject()
  );

  if (popup) {
    popupCenter(event, url, 560, 725);
  }

  if (socialShare) {
    trackSocial(socialShare.platform, socialShare.action);
    trainParselyProfile();
  }

  // Run onShare callback to close share menu for redesign components
  if (isRedesign && props.onShare) {
    props.onShare();
  }
}

function renderSocialList(empty, properties, props) {
  const overrideColor = get(props, 'overrideColor', '');
  const redesign = get(props, 'redesign', false);

  return (
    <ul className={`social__list ${empty ? 'social__list--post-empty' : ''}`}>
      {properties.map((sharer, index) => {
        const { property, url, icon = '', popup, socialShare } = sharer;

        return (
          <li
            key={property}
            className={`social__item ${empty ? 'social__item--empty' : ''}`}
          >
            <a
              className={`social__link social__link--${property}`}
              href={url}
              onClick={e =>
                handleClick(e, url, popup, socialShare, props, index, redesign)
              }
              rel="noopener noreferrer"
            >
              {sharer.property === 'twitter' ? (
                <SocialIcon type="x" width="14px" height="14px" color="#fff" />
              ) : (
                <i
                  style={overrideColor ? { color: `${overrideColor}` } : {}}
                  className={`icon-${icon || property}`}
                />
              )}
              {redesign && (
                <span className="social__link-text">{property}</span>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

function Social(props) {
  if (!props) {
    return null;
  }

  const encodedPostUrl = props.uri;
  const encodedTitle = props.text || props.socialText;
  let properties = socialModel(encodedPostUrl, encodedTitle, props.subject);
  properties = properties.filter(p => p.property !== 'email');
  const classes = ['social', `social--${props.modifier}`];
  const social = useRef();
  if (!props.active) {
    classes.push('social--hidden');
  }

  return (
    <div
      ref={social}
      className={clsx(
        'social',
        props.modifier && `social--${props.modifier}`,
        !props.active && 'social--hidden',
        props.horizontal && 'social--horizontal'
      )}
      id={props.encapsulateId}
      style={props.disableStyle ? {} : {}}
    >
      {renderSocialList(props.empty, properties, props)}
    </div>
  );
}

Social.defaultProps = {
  uri: 'https://www.fastcompany.com',
  text:
    'Fast Company is the world&#x27;s leading progressive business media brand, with a unique editorial focus on innovation in technology, leadership, and design.',
  modifier: 'default',
  // topValue: 100,
  subject: '',
  // bottomBoundary: 0,
  encapsulateId: '',
  empty: false,
  socialText: {
    twitter: '',
    linkedin: '',
    email: ''
  },
  active: true,
  disableStyle: false,
  overrideColor: '',
  tags: [],
  horizontal: false
  // post: {},
};

Social.propTypes = {
  empty: PropTypes.bool,
  modifier: PropTypes.string,
  uri: PropTypes.string,
  subject: PropTypes.string,
  text: PropTypes.string,
  disableStyle: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  socialText: PropTypes.shape({
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    email: PropTypes.string
  }),
  // bottomBoundary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encapsulateId: PropTypes.string,
  // topValue: PropTypes.number,
  active: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  overrideColor: PropTypes.string, // it is used, linter is being weird
  // eslint-disable-next-line react/no-unused-prop-types
  tags: PropTypes.oneOfType([PropTypes.array]),
  horizontal: PropTypes.bool
  // post: PropTypes.shape({
  //   categories: PropTypes.oneOfType([PropTypes.array])
  // }),
  // advertorial: PropTypes.bool
};

export default Social;
