import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    width="10"
    viewBox="0 0 320 512"
    style={{ width, height, fill: color }}
  >
    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
  </svg>
);

Facebook.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

Facebook.defaultProps = {
  width: '10px',
  height: '16px',
  color: '#7f7f7f'
};

export default Facebook;
