import React from 'react';
import PropTypes from 'prop-types';

const X = ({ width, height, color }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width, height, fill: color }}
  >
    <path d="M8.66152 6.38584L13.6962 0.457794H12.5031L8.13153 5.60503L4.63994 0.457794H0.612793L5.89277 8.24133L0.612793 14.4578H1.80592L6.42246 9.02214L10.1098 14.4578H14.137L8.66123 6.38584H8.66152ZM7.02737 8.3099L6.4924 7.53484L2.23582 1.36757H4.06839L7.5035 6.34475L8.03847 7.11982L12.5037 13.5894H10.6711L7.02737 8.3102V8.3099Z" />
  </svg>
);

X.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

X.defaultProps = {
  width: '15px',
  height: '15px',
  color: '#7f7f7f'
};

export default X;
