/* eslint-disable global-require, import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';

const SocialIcon = ({ type, width, height, color }) => {
  const ReturnedIcon = require(`./icon_${type.toLowerCase()}`).default;
  return (
    <ReturnedIcon
      {...width && { width }}
      {...height && { height }}
      {...color && { color }}
    />
  );
};

SocialIcon.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

SocialIcon.defaultProps = {
  width: null,
  height: null,
  color: null
};

export default SocialIcon;
